import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    //user prefered currency
    preferedCurrency: localStorage.getItem('currency'),
    supportedCurrencies: [],
    exchangeRatesForPreferedCurrency: JSON.parse(
      localStorage.getItem('exchangeRates')
    ),
    // component states
    imgIndex: -1,
    isLoading: false,
    compareHotelList: [],
    progress: 0,
    user: [],
    // user profile
    userProfile: [],
    isUserProfile: false,
    isUserProfileError: false,
    backgroundImageLists: [],
    // home page content
    isBackgroundImages: false,
    isBackgroundImagesError: false,
    topDestination: [],
    isTopDestination: false,
    isTopDestinationError: false,
    isPopularAttractoinsCountries: false,
    popularAttractoinsCountries: [],
    popularAttractoinsList: [],
    isCitiesList: false,
    citiesList: [],
    isCitiesListError: false,
    // forget password
    isPasswordUpdated: false,
    isLoginPasswordUpdated: false,
    isOTPMailSent: false,
    isResetPasswordMailSent: false,
    isResetOtpSuccess: false,
    isAbletoResetPassword: false,
    isOTPMailReSent: false,
    userVerifyOTPForResetPassword: false,
    userVerifyOTPForEmailUpdate: false,
    userOTP: [],
    //  trip generator
    isTripGenerating: false,
    generatedTrip: [{ tableName: [] }],
    detailedTripInfo: false,
    isGeneratedTripError: false,
    generatedTripError: [],
    // flights airport search
    isFlightLoading: false,
    flightList: [],
    isFlightError: false,
    flightError: [],
    // flight popular routes
    isFlightPopularRoutesLoading: false,
    flightPopularRoutesList: [],
    isFlightPopularRoutesError: false,
    flightPopularRoutesError: [],
    // flight search resutl
    isFlightDataLoading: false,
    flightData: [],
    isFlightDataError: false,
    flightDataError: [],
    // get Flight Revalidate

    //get old data of change flight
    isOldChangeFlightDataLoading: false,
    oldFlightData: {},
    isOldChangeFlightDataError: false,
    oldFlightDataError: '',

    //get change flight data
    isChangeFlightDataLoading: false,
    changeFlightData: [],
    isChangeFlightDataError: false,
    changeFlightDataError: [],

    //change flight process data
    isChangeFlightProcessLoading: false,
    changeFlightProcessResponse: [],
    isChangeFlightProcessError: false,
    changeFlightProcessError: [],

    //change flight
    changeflightConfirObject: {},

    //type and currancy
    type: '',
    currency: '',

    //cancel flight
    isCancelFlightLoading: false,
    cancelFlightResponse: [],
    isCancelFlightError: false,
    cancelFlightError: [],

    isFlightValidLoading: false,
    validFlightData: [],
    usedBucks: 0,
    isFlightValidEroor: false,
    validFlightError: [],

    // cancel hotel
    isCancelHotelLoading: false,
    cancelHotelResponse: [],
    isCancelHotelError: false,
    cancelHotelError: [],

    //bucks
    isBucksApplied: false,

    //flight change
    isFlightChanging: false,

    // get Frequent Travelers info
    isFrequentTravelerDataLoading: false,
    frequentTravelerData: [],
    isFrequentTravelerDataError: false,
    frequentTravelerDataError: [],
    // set multiflight data
    multiFlightList: [
      {
        id: 0,
        isOpen: false,
        isOpen2: false,
        departingAirportName: '',
        destinationAirportName: '',
        destinationCityName: '',
        departingCityName: '',
        departureDate: dayjs(new Date()).format('YYYY-MM-DD'),
        airportOriginCode: '',
        airportDestinationCode: '',
        isValidDepartError: false,
        isValidReturnError: false,
      },
      {
        id: 1,
        isOpen: false,
        isOpen2: false,
        departingAirportName: '',
        destinationAirportName: '',
        destinationCityName: '',
        departingCityName: '',
        departureDate: dayjs(new Date()).format('YYYY-MM-DD'),
        airportOriginCode: '',
        airportDestinationCode: '',
        isValidDepartError: false,
        isValidReturnError: false,
      },
    ],
    // get top Cities
    isGetTopCitiesLoading: false,
    getTopCitiesData: [],
    isGetTopCitiesError: false,
    getTopCitiesError: [],
    // get Hotel And Cities
    isGetHotelAndCitiesLoading: false,
    hotelAndCitiesData: [],
    isGetHotelAndCitiesError: false,
    hotelAndCitiesError: [],
    //get car rental locations
    isGetLocationsLoding: false,
    locationsData: [],
    isGetLocationsError: false,
    locationsError: [],
    // get Hotel List
    isGetHotelListLoading: false,
    hotelListData: [],
    isGetHotelListError: false,
    hotelListError: [],
    // get Hotel By city Name
    isGetHotelByCityLoading: false,
    hotelByCityData: [],
    isGetHotelByCityError: false,
    hotelByCityError: [],
    // get Hotel By Id
    isGetHotelByIdLoading: false,
    isGetHotelByIdError: false,
    hotelByIdData: [],
    hotelByIdError: [],
    // get Hotel Metadata
    isGetHotelMetadataLoading: false,
    isGetHotelMetadataError: false,
    hotelMetadataData: [],
    hotelMetadataError: [],
    // get Hotel Reviews
    isGetHotelReviewLoading: false,
    isGetHotelReviewError: false,
    hotelReviewData: [],
    hotelReviewError: [],
    // get Hotel Rooms
    isGetHotelRoomsLoading: false,
    isGetHotelRoomsError: false,
    hotelRoomsData: [],
    hotelRoomsError: [],
    // get Airport Lounge
    isGetLounageLoading: false,
    isGetLounageError: false,
    loungeData: [],
    loungeError: [],
    // get Hotels Cordinate
    isGetHotelCordinateLoading: false,
    hotelsCordinate: [],
    isGetHotelCordinateError: false,
    hotelsCordinateError: [],
    // error

    isPopularAttractions: false,
    isPopularAttractionsError: false,
    isError: false,
    isUserLoggedIn: false,
    error: [],
    // events
    eventsData: [],
    eventsError: [],
    isGetEventsLoading: false,
    isGetEventsError: false,
    // get event by id
    eventByIdData: [],
    eventByIdError: [],
    isGetEventByIdLoading: false,
    isGetEventByIdError: false,
    // Get events categories
    eventsCategoriesData: [],
    eventsCategoriesError: [],
    isGetEventsCategoriesLoading: false,
    isGetEventsCategoriesError: false,
    //Get event country
    eventsCountriesData: [],
    eventsCountriesError: [],
    isGetEventsCountriesLoading: false,
    isGetEventsCountriesError: false,
    //Get event cities
    eventCitiesData: [],
    eventCitiesError: [],
    isGetEventCitiesLoading: false,
    isGetEventCitiesError: false,
    // get hotel book
    isHotelBookLoading: false,
    isHotelBookError: false,
    hotelBookData: [],
    hotelBookError: [],
    isFlightBookLoading: false,
    flightBookData: [],
    isFlightBookError: false,
    flightBookError: [],
    setBookingStates: 'all',
    bookingState: 'all',

    //booking Status
    bookingStatus: false,

    // car rental
    carData: [],
    carErrors: [],
    isGetCarLoading: false,
    isGetCarError: false,

    //Ip address
    ipData: {},
    // trip planner activties update
    activitesUpdating: false,

    // hotel price type
    hotelPriceType: 'total',
  },
  reducers: {
    //user prefered currency
    setPreferedCurrency: (state, action) => {
      state.preferedCurrency = action.payload;
    },
    setSupportedCurrencies: (state, action) => {
      state.supportedCurrencies = action.payload;
    },
    setExchangeRateForPreferedCurrency: (state, action) => {
      state.exchangeRatesForPreferedCurrency = action.payload;
    },
    // component state
    setImgIndex: (state, action) => {
      state.imgIndex = action.payload;
    },
    setCompareHotel: (state, action) => {
      state.compareHotelList = action.payload;
    },
    removeCompareHotel: (state, action) => {
      state.compareHotelList = state.compareHotelList.filter(
        (el) => el.hotel_id !== action.payload
      );
    },
    // set progress
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setMultiFlight: (state, action) => {
      state.multiFlightList = action.payload;
    },
    removeMultiFlight: (state, action) => {
      state.multiFlightList = state.multiFlightList.filter(
        (el) => el.id !== action.payload
      );
    },
    // reset bucks
    resetBucks: (state) => {
      state.usedBucks = 0;
    },
    // login reducers
    userLogin: (state) => {
      state.isLoading = true;
      state.error = [];
      state.userOTP = [];
      state.isError = false;
      state.progress = 30;
    },
    userLoginSuccess: (state, action) => {
      state.error = [];
      state.isError = false;
      state.userOTP = action.payload;
      state.isUserLoggedIn = action.payload.role === 'admin' ? true : false;
      state.isOTPMailSent = true;
      state.progress = 70;
      state.isLoading = false;
    },
    userLoginFailure: (state, action) => {
      state.userOTP = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    userInputErrorHandle: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.error = [];
      state.progress = 0;
      state.isGeneratedTripError = false;
      state.generatedTripError = [];
    },
    // otp verify reducers
    userVerifyOTP: (state) => {
      state.isLoading = true;
    },
    userVerifyOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.isOTPMailSent = false;
      state.isUserLoggedIn = true;
      state.isResetOtpSuccess = true;
      state.isAbletoResetPassword = true;
      state.userVerifyOTPForResetPassword = true;
      state.userVerifyOTPForEmailUpdate = true;
      state.user = action.payload;
      state.error = [];
      state.isError = false;
    },
    userVerifyOTPFailure: (state, action) => {
      state.isLoading = false;
      state.isUserLoggedIn = false;
      state.user = [];
      state.isResetOtpSuccess = false;
      state.isAbletoResetPassword = false;
      state.userVerifyOTPForResetPassword = false;
      state.userVerifyOTPForEmailUpdate = false;
      state.error = action.payload;
      state.isError = true;
    },

    userVerifyOTPCancel: (state) => {
      state.isResetOtpSuccess = false;
      state.isOTPMailSent = false;
      state.isAbletoResetPassword = false;
      state.userVerifyOTPForResetPassword = false;
      state.userVerifyOTPForEmailUpdate = false;
    },
    // resend OTP
    userResendOTP: (state) => {
      state.isLoading = true;
      state.isOTPMailReSent = true;
    },
    userResendOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.userOTP = action.payload;
      state.isUserLoggedIn = false;
      state.isOTPMailSent = true;
      state.isOTPMailReSent = false;
    },
    userResendOTPFailure: (state, action) => {
      state.isLoading = false;
      state.userOTP = [];
      state.isError = true;
      state.error = action.payload;
      state.isOTPMailReSent = false;
    },
    // user sign up
    userSignup: (state) => {
      state.isLoading = true;
      state.progress = 70;
      state.error = [];
      state.isError = false;
    },
    userSignupSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.userOTP = action.payload;
      state.isOTPMailSent = true;
      state.isError = false;
      state.error = [];
    },
    userSignupFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isOTPMailSent = false;
      state.error = action.payload;
    },
    // user reset password
    userPasswordReset: (state) => {
      state.isLoading = true;
      state.isResetPasswordMailSent = false;
      state.progress = 30;
      state.error = [];
      state.isError = false;
    },
    userPasswordResetSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.isResetPasswordMailSent = true;
      state.userOTP = action.payload;
      state.error = [];
      state.isError = false;
    },
    userPasswordResetFailure: (state, action) => {
      state.isLoading = false;
      state.progress = 0;
      state.isResetPasswordMailSent = false;
      state.isError = true;
      state.error = action.payload;
    },
    // user password update
    userPasswordUpdate: (state) => {
      state.isLoading = true;
      state.userOTP = [];
      state.isOTPMailSent = false;
      state.progress = 30;
      state.error = [];
      state.isError = false;
      state.isPasswordUpdated = false;
    },
    userPasswordUpdateSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.isOTPMailSent = false;
      state.userOTP = action.payload;
      state.error = [];
      state.isError = false;
      state.isPasswordUpdated = true;
    },
    userPasswordUpdateFailure: (state, action) => {
      state.isLoading = false;
      state.progress = 0;
      state.isOTPMailSent = false;
      state.isError = true;
      state.error = action.payload;
      state.isPasswordUpdated = false;
    },

    userAfterLoginResetPasswordUpdate: (state) => {
      state.isLoading = true;
      state.userOTP = [];
      state.progress = 30;
      state.error = [];
      state.isError = false;
      state.isLoginPasswordUpdated = false;
    },
    userAfterLoginResetPasswordUpdateSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.userOTP = action.payload;
      state.error = [];
      state.isError = false;
      state.isLoginPasswordUpdated = true;
    },
    userAfterLoginResetPasswordUpdateFailure: (state, action) => {
      state.isLoading = false;
      state.progress = 0;
      state.isError = true;
      state.error = action.payload;
      state.isLoginPasswordUpdated = false;
    },
    userAfterLoginResetPasswordUpdateCancel: (state) => {
      state.isLoginPasswordUpdated = false;
    },

    //user password reset by OTP
    userResetPassWordOTP: (state) => {
      state.isLoading = true;
      state.userOTP = [];
      state.isOTPMailSent = false;
      state.progress = 70;
      state.error = [];
      state.isError = false;
    },

    userResetPassWordOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.isOTPMailSent = true;
      state.userOTP = action.payload;
      state.error = [];
      state.isError = false;
    },
    userResetPassWordOTPFailure: (state, action) => {
      state.isLoading = false;
      state.progress = 0;
      state.isOTPMailSent = false;
      state.isError = true;
      state.error = action.payload;
    },

    userEmailUpdateOTP: (state) => {
      state.isLoading = true;
      state.userOTP = [];
      state.progress = 70;
      state.error = [];
      state.isError = false;
    },

    userEmailUpdateOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.userOTP = action.payload;
      state.isEmailUpdateOTPSent = true;
      state.error = [];
      state.isError = false;
    },
    userEmailUpdateOTPFailure: (state, action) => {
      state.isLoading = false;
      state.progress = 0;
      state.isError = true;
      state.isEmailUpdateOTPSent = false;
      state.error = action.payload;
    },

    userEmailUpdateOTPCancel: (state) => {
      state.isError = false;
      state.isEmailUpdateOTPSent = false;
      state.userVerifyOTPForResetPassword = false;
    },

    userEmailUpdate: (state) => {
      state.isLoading = true;
      state.progress = 70;
      state.isError = false;
    },
    userEmailUpdateSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.userOTP = action.payload;
      // state.isEmailUpdateOTPSent = true
      state.error = [];
      state.isError = false;
    },

    userEmailUpdateFailure: (state, action) => {
      state.isLoading = false;
      state.progress = 0;
      state.isError = true;
      // state.isEmailUpdateOTPSent = false
      state.error = action.payload;
    },

    userEmailUpdateCancel: (state) => {
      state.isError = false;
      // state.isEmailUpdateOTPSent = false
      state.userVerifyOTPForResetPassword = false;
    },

    userLoginResetPasswordCancel: (state) => {
      state.isOTPMailSent = false;
      state.isResetOtpSuccess = false;
      state.isError = false;
    },

    userResetPasswordScreenCancel: (state) => {
      state.isAbletoResetPassword = false;
      state.userVerifyOTPForResetPassword = false;
    },

    userAfterLoginResetPasswordSet: (state) => {
      state.isLoading = true;
      // state.isResetPasswordMailSent = false
      state.progress = 30;
      state.error = [];
      state.isError = false;
    },
    userAfterLoginResetPasswordSetSuccess: (state, action) => {
      state.isLoading = false;
      state.progress = 100;
      state.userOTP = action.payload;
      state.error = [];
      state.isError = false;
    },
    userAfterLoginResetPasswordSetFailure: (state, action) => {
      state.isLoading = false;
      state.progress = 0;
      // state.isResetPasswordMailSent = false
      state.isError = true;
      state.error = action.payload;
    },

    // get top destination list
    getTopDestination: (state) => {
      state.isTopDestination = true;
      state.topDestination = [];
      state.isTopDestinationError = false;
    },
    getTopDestinationSuccess: (state, action) => {
      state.isTopDestination = false;
      state.topDestination = action.payload;
      state.isTopDestinationError = false;
    },
    getTopDestinationFailure: (state, action) => {
      state.isTopDestination = false;
      state.error = action.payload;
      state.isTopDestinationError = true;
    },
    // get popular attractions countries list
    getPopularAttractionCountries: (state) => {
      state.isLoading = true;
      state.isPopularAttractoinsCountries = true;
      state.popularAttractoinsCountries = [];
      state.isError = false;
    },
    getPopularAttractionCountriesSuccess: (state, action) => {
      state.isLoading = false;
      state.isPopularAttractoinsCountries = false;
      state.popularAttractoinsCountries = action.payload;
      state.isError = false;
    },
    getPopularAttractionCountriesFailure: (state, action) => {
      state.isLoading = false;
      state.isPopularAttractoinsCountries = false;
      state.error = action.payload;
      state.isError = true;
    },
    // get popular attractions list
    getPopularAttraction: (state) => {
      state.isPopularAttractions = true;
      state.popularAttractoinsList = [];
      state.isPopularAttractionsError = false;
    },
    getPopularAttractionSuccess: (state, action) => {
      state.isPopularAttractions = false;
      state.popularAttractoinsList = action.payload;
      state.isPopularAttractionsError = false;
    },
    getPopularAttractionFailure: (state, action) => {
      state.isPopularAttractions = false;
      state.error = action.payload;
      state.isPopularAttractionsError = true;
    },
    // get Backgroung Images List
    getBackgroungImagesList: (state) => {
      state.isBackgroundImages = true;
      state.backgroundImageLists = [];
      state.isBackgroundImagesError = false;
    },
    getBackgroungImagesListSuccess: (state, action) => {
      state.isBackgroundImages = false;
      state.backgroundImageLists = action.payload;
      state.isBackgroundImagesError = false;
    },
    getBackgroungImagesListFailure: (state, action) => {
      state.isBackgroundImages = false;
      state.error = action.payload;
      state.isBackgroundImagesError = true;
    },
    // get User Profile
    getUserProfile: (state) => {
      state.isUserProfile = true;
      state.isProfileLoading = true;
      state.userProfile = [];
      state.isUserProfileError = false;
    },
    getUserProfileSuccess: (state, action) => {
      state.isUserProfile = false;
      state.isProfileLoading = false;
      state.userProfile = action.payload;
      state.isUserProfileError = false;
    },
    getUserProfileFailure: (state, action) => {
      state.isUserProfile = false;
      state.isProfileLoading = false;
      state.error = action.payload;
      state.isUserProfileError = true;
    },
    // get cities list
    getCitiesList: (state) => {
      state.isCitiesList = true;
      state.citiesList = [];
      state.isCitiesListError = false;
    },
    getCitiesListSuccess: (state, action) => {
      state.isCitiesList = false;
      state.citiesList = action.payload;
      state.isCitiesListError = false;
    },
    getCitiesListFailure: (state, action) => {
      state.isCitiesList = false;
      state.error = action.payload;
      state.isCitiesListError = true;
    },
    // trip generator
    generateTrip: (state) => {
      state.progress = 60;
      state.isTripGenerating = true;
      state.generatedTrip = [];
      state.isGeneratedTripError = false;
    },
    generateTripSuccess: (state, action) => {
      state.progress = 100;
      state.isTripGenerating = false;
      state.generatedTrip = action.payload;
      state.isGeneratedTripError = false;
    },
    generateTripFailure: (state, action) => {
      state.progress = 100;
      state.isTripGenerating = false;
      state.generatedTripError = action.payload;
      state.isGeneratedTripError = true;
    },
    // get detailed trip info
    setDetailedTripInfo: (state, action) => {
      state.detailedTripInfo = action.payload;
    },
    // flight search
    getFlightsList: (state) => {
      state.isFlightLoading = true;
      state.flightList = [];
      state.isFlightError = false;
    },
    getFlightSuccess: (state, action) => {
      state.isFlightLoading = false;
      state.flightList = action.payload;
      state.isFlightError = false;
    },
    getFlightFailure: (state, action) => {
      state.isFlightLoading = false;
      state.flightError = action.payload;
      state.isFlightError = true;
    },
    // flight Popular Routes
    getFlightsPopularRoutesList: (state) => {
      state.isFlightPopularRoutesLoading = true;
      state.flightPopularRoutesList = [];
      state.isFlightPopularRoutesError = false;
    },
    getFlightPopularRoutesSuccess: (state, action) => {
      state.isFlightPopularRoutesLoading = false;
      state.flightPopularRoutesList = action.payload;
      state.isFlightPopularRoutesError = false;
    },
    getFlightPopularRoutesFailure: (state, action) => {
      state.isFlightPopularRoutesLoading = false;
      state.flightPopularRoutesError = action.payload;
      state.isFlightPopularRoutesError = true;
    },
    // flight Popular Routes
    getFlightsData: (state) => {
      state.progress = 50;
      state.isFlightDataLoading = true;
      state.flightData = [];
      state.isFlightDataError = false;
    },
    getFlightDataSuccess: (state, action) => {
      state.progress = 100;
      state.isFlightDataLoading = false;
      state.flightData = action.payload;
      state.isFlightDataError = false;
    },
    getFlightDataFailure: (state, action) => {
      state.progress = 100;
      state.isFlightDataLoading = false;
      state.flightDataError = action.payload;
      state.isFlightDataError = true;
    },
    //old flight data
    getFlightDetails: (state) => {
      state.isOldChangeFlightDataLoading = true;
      state.oldFlightData = {};
      state.isOldChangeFlightDataError = false;
    },

    getFlightDetailsSuccess: (state, action) => {
      state.isOldChangeFlightDataLoading = false;
      state.oldFlightData = action.payload;
      state.isOldChangeFlightDataError = false;
    },

    getFlightDetailsFailure: (state, action) => {
      state.isOldChangeFlightDataLoading = false;
      state.isOldChangeFlightDataError = true;
      state.oldFlightDataError = action.payload;
    },

    // change flight data
    getChangeFlightConfirmData: (state, action) => {
      state.changeflightConfirObject = { ...action.payload };
    },

    getChangeFlightData: (state) => {
      state.progress = 50;
      state.isChangeFlightDataLoading = true;
      state.changeFlightData = [];
      state.isChangeFlightDataError = false;
    },
    getChangeFlightDataSucces: (state, action) => {
      state.progress = 100;
      state.isChangeFlightDataLoading = false;
      state.changeFlightData = action.payload;
      state.isChangeFlightDataError = false;
    },

    getChangeFlightDataFailure: (state, action) => {
      state.progress = 100;
      state.isChangeFlightDataLoading = false;
      state.changeFlightDataError = action.payload;
      state.isChangeFlightDataError = true;
    },

    getchangeFlightProcessData: (state) => {
      state.isChangeFlightProcessLoading = true;
      state.changeFlightProcessResponse = [];
      state.isChangeFlightProcessError = false;
    },

    getchangeFlightProcessDataSuccess: (state, action) => {
      state.isChangeFlightProcessLoading = false;
      state.changeFlightProcessResponse = action.payload;
      state.isChangeFlightProcessError = false;
    },

    getchangeFlightProcessDataFailure: (state, action) => {
      state.isChangeFlightProcessLoading = false;
      state.changeFlightProcessError = action.payload;
      state.isChangeFlightProcessError = true;
    },

    //cancel flight
    cancelFlight: (state) => {
      state.progress = 50;
      state.cancelFlightResponse = [];
      state.cancelFlightError = [];
      state.isCancelFlightLoading = true;
      state.isCancelFlightError = false;
    },

    cancelFlightSuccess: (state, action) => {
      state.progress = 100;
      state.cancelFlightResponse = action.payload;
      state.isCancelFlightLoading = false;
      state.isCancelFlightError = false;
      state.cancelFlightError = [];
    },

    cancelFlightFailure: (state, action) => {
      state.progress = 100;
      state.isCancelFlightLoading = false;
      state.isCancelFlightError = true;
      state.cancelFlightError = action.payload;
      state.cancelFlightResponse = [];
    },
    // Hotel
    cancelHotel: (state) => {
      state.progress = 50;
      state.cancelHotelResponse = [];
      state.cancelHotelError = [];
      state.isCancelHotelLoading = true;
      state.isCancelHotelError = false;
    },

    cancelHotelSuccess: (state, action) => {
      state.progress = 100;
      state.cancelHotelResponse = action.payload;
      state.isCancelHotelLoading = false;
      state.isCancelHotelError = false;
      state.cancelHotelError = [];
    },

    cancelHotelFailure: (state, action) => {
      state.progress = 100;
      state.isCancelHotelLoading = false;
      state.isCancelHotelError = true;
      state.cancelHotelError = action.payload;
      state.cancelHotelResponse = [];
    },

    // get top cities
    getTopCities: (state) => {
      state.isGetTopCitiesLoading = true;
      state.isGetTopCitiesError = false;
      state.getTopCitiesData = [];
      state.getTopCitiesError = [];
    },
    getTopCitiesSuccess: (state, action) => {
      state.isGetTopCitiesLoading = false;
      state.isGetTopCitiesError = false;
      state.getTopCitiesData = action.payload;
      state.getTopCitiesError = [];
    },
    getTopCitiesFailure: (state, action) => {
      state.isFlightDataLoading = false;
      state.flightDataError = action.payload;
      state.isFlightDataError = true;
    },

    // get hotel and citu name
    getHotelAndCities: (state) => {
      state.isGetHotelAndCitiesLoading = true;
      state.hotelAndCitiesData = false;
      state.isGetHotelAndCitiesError = [];
      state.hotelAndCitiesError = [];
    },
    getHotelAndCitiesSuccess: (state, action) => {
      state.isGetHotelAndCitiesLoading = false;
      state.isGetHotelAndCitiesError = false;
      state.hotelAndCitiesData = action.payload;
      state.hotelAndCitiesError = [];
    },
    getHotelAndCitiesFailure: (state, action) => {
      state.isGetHotelAndCitiesLoading = false;
      state.hotelAndCitiesError = action.payload;
      state.isGetHotelAndCitiesError = true;
    },
    //get Locations for car rental
    getLocations: (state) => {
      state.isGetLocationsLoding = true;
      state.locationsData = false;
      state.isGetLocationsError = [];
      state.locationsError = [];
    },
    getLocationsSuccess: (state, action) => {
      state.isGetLocationsLoding = false;
      state.locationsData = action.payload;
      state.isGetLocationsError = false;
      state.locationsError = [];
    },
    getLocationsFailure: (state, action) => {
      state.isGetLocationsLoding = false;
      state.locationsError = action.payload;
      state.isGetLocationsError = true;
    },

    // get hotel list data
    getHotelList: (state) => {
      state.progress = 85;
      state.isGetHotelListLoading = true;
      state.isGetHotelListError = false;
      state.hotelListData = [];
      state.hotelListError = [];
    },
    getHotelListSuccess: (state, action) => {
      state.progress = 100;
      state.isGetHotelListLoading = false;
      state.isGetHotelListError = false;
      state.hotelListData = action.payload;
      state.hotelListError = [];
    },
    getHotelListFailure: (state, action) => {
      state.progress = 100;
      state.isGetHotelListLoading = false;
      state.hotelListError = action.payload;
      state.isGetHotelListError = true;
    },
    // get hotels by city name
    getHotelByCity: (state) => {
      state.isGetHotelByCityLoading = true;
      state.isGetHotelByCityError = false;
      state.hotelByCityData = [];
      state.hotelByCityError = [];
    },
    getHotelByCitySuccess: (state, action) => {
      state.isGetHotelByCityLoading = false;
      state.isGetHotelByCityError = false;
      state.hotelByCityData = action.payload;
      state.hotelByCityError = [];
    },
    getHotelByCityFailure: (state, action) => {
      state.isGetHotelByCityLoading = false;
      state.hotelByCityError = action.payload;
      state.isGetHotelByCityError = true;
    },
    // get hotels by city name
    getHotelById: (state) => {
      state.isGetHotelByIdLoading = true;
      state.isGetHotelByIdError = false;
      state.hotelByIdData = [];
      state.hotelByIdError = [];
    },
    getHotelByIdSuccess: (state, action) => {
      state.isGetHotelByIdLoading = false;
      state.isGetHotelByIdError = false;
      state.hotelByIdData = action.payload;
      state.hotelByIdError = [];
    },
    getHotelByIdFailure: (state, action) => {
      state.isGetHotelByIdLoading = false;
      state.hotelByIdError = action.payload;
      state.isGetHotelByIdError = true;
    },
    // get hotels metadata
    getHotelMetadataById: (state) => {
      state.isGetHotelMetadataLoading = true;
      state.isGetHotelMetadataError = false;
      state.hotelMetadataData = [];
      state.hotelMetadataError = [];
    },
    getHotelMetadataByIdSuccess: (state, action) => {
      state.isGetHotelMetadataLoading = false;
      state.isGetHotelMetadataError = false;
      state.hotelMetadataData = action.payload;
      state.hotelMetadataError = [];
    },
    getHotelMetadataByIdFailure: (state, action) => {
      state.isGetHotelMetadataLoading = false;
      state.hotelMetadataError = action.payload;
      state.isGetHotelMetadataError = true;
    },
    // get hotels review
    getHotelReviewById: (state) => {
      state.isGetHotelReviewLoading = true;
      state.isGetHotelReviewError = false;
      state.hotelReviewData = [];
      state.hotelReviewError = [];
    },
    getHotelReviewByIdSuccess: (state, action) => {
      state.isGetHotelReviewLoading = false;
      state.isGetHotelReviewError = false;
      state.hotelReviewData = action.payload;
      state.hotelReviewError = [];
    },
    getHotelReviewByIdFailure: (state, action) => {
      state.isGetHotelReviewLoading = false;
      state.hotelReviewError = action.payload;
      state.isGetHotelReviewError = true;
    },
    // get hotels rooms
    getHotelRoomsById: (state) => {
      state.isGetHotelRoomsLoading = true;
      state.isGetHotelRoomsError = false;
      state.hotelRoomsData = [];
      state.hotelRoomsError = [];
    },
    getHotelRoomsByIdSuccess: (state, action) => {
      state.isGetHotelRoomsLoading = false;
      state.isGetHotelRoomsError = false;
      state.hotelRoomsData = action.payload;
      state.hotelRoomsError = [];
    },
    getHotelRoomsByIdFailure: (state, action) => {
      state.isGetHotelRoomsLoading = false;
      state.hotelRoomsError = action.payload;
      state.isGetHotelRoomsError = true;
    },
    // get hotels cordinate
    getHotelsCordinateByCity: (state) => {
      state.isGetHotelCordinateLoading = true;
      state.isGetHotelCordinateError = false;
      state.hotelsCordinate = [];
      state.hotelsCordinateError = [];
    },
    getHotelsCordinateByCitySuccess: (state, action) => {
      state.isGetHotelCordinateLoading = false;
      state.isGetHotelCordinateError = false;
      state.hotelsCordinate = action.payload;
      state.hotelsCordinateError = [];
    },
    getHotelsCordinateByCityFailure: (state, action) => {
      state.isGetHotelCordinateLoading = false;
      state.isGetHotelCordinateError = true;
      state.hotelsCordinate = [];
      state.hotelsCordinateError = action.payload;
    },
    // flight revalidate
    getFlightRevalidate: (state) => {
      state.progress = 65;
      state.isFlightValidLoading = true;
      // state.validFlightData = []
      state.isFlightValidEroor = false;
      state.validFlightError = [];
    },
    getFlightRevalidateSuccess: (state, action) => {
      state.progress = 100;
      state.isFlightValidLoading = false;
      state.validFlightData = action.payload;
      state.isFlightValidEroor = false;
      state.validFlightError = [];
    },
    getFlightRevalidateFailure: (state, action) => {
      state.progress = 100;
      state.isFlightValidLoading = false;
      state.validFlightData = [];
      state.isFlightValidEroor = true;
      state.validFlightError = action.payload;
    },

    getFlightRevalidateReset: (state) => {
      state.isFlightValidLoading = false;
      state.validFlightData = [];
      state.isFlightValidEroor = false;
      state.validFlightError = [];
      state.isFlightDataLoading = false;
      state.flightData = [];
      state.isFlightDataError = false;
    },

    //bucks applied for flight
    setBucksApply: (state, action) => {
      state.isBucksApplied = true;
      state.validFlightData.fareDetails = {
        ...state.validFlightData.fareDetails,
        total:
          Number(state.validFlightData.fareDetails.total) -
          Number(action.payload),
      };
      state.validFlightData = {
        ...state.validFlightData,
        total: Number(state.validFlightData.total) - Number(action.payload),
      };
      state.usedBucks = Number(action.payload);
    },

    setBucksNotApply: (state, action) => {
      state.isBucksApplied = false;
      state.validFlightData.fareDetails = {
        ...state.validFlightData.fareDetails,
        total:
          Number(state.validFlightData.fareDetails.total) +
          Number(action.payload),
      };
      state.validFlightData = {
        ...state.validFlightData,
        total: Number(state.validFlightData.total) + Number(action.payload),
      };
      state.usedBucks = Number(state.usedBucks) - Number(action.payload);
    },

    // flight change price
    setFlightChangePrice: (state, action) => {
      state.isFlightChanging = true;
      state.validFlightData.fareDetails = {
        ...state.validFlightData.fareDetails,
        total: Number(action.payload),
      };
      state.validFlightData = {
        ...state.validFlightData,
        total: Number(action.payload),
      };
    },
    setFlightChangePriceOff: (state, action) => {
      state.isFlightChanging = false;
    },

    //bucks applied for hotel
    setBucksApplyHotel: (state, action) => {
      state.isBucksApplied = true;

      state.hotelByIdData.data.hotel_rooms[0] = {
        ...state.hotelByIdData.data.hotel_rooms[0],
        block: state.hotelByIdData.data.hotel_rooms[0].block.map((el) => {
          if (Number(el.room_id) === Number(action.payload.roomId)) {
            return {
              ...el,
              min_price: {
                ...el.min_price,
                price:
                  Number(el.min_price.price) - Number(action.payload.amount),
              },
            };
          } else {
            return { ...el };
          }
        }),
      };
    },

    setBucksNotApplyHotel: (state, action) => {
      state.isBucksApplied = false;

      state.hotelByIdData.data.hotel_rooms[0] = {
        ...state.hotelByIdData.data.hotel_rooms[0],
        block: state.hotelByIdData.data.hotel_rooms[0].block.map((el) => {
          if (Number(el.room_id) === Number(action.payload.roomId)) {
            return {
              ...el,
              min_price: {
                ...el.min_price,
                price:
                  Number(el.min_price.price) + Number(action.payload.amount),
              },
            };
          } else {
            return { ...el };
          }
        }),
      };
    }, // convert value from doller to indian inr for hotel bucks apply

    setBucksApplyFalse: (state) => {
      state.isBucksApplied = false;
    },

    // airport Lounge
    getAirportLounge: (state, action) => {
      state.progress = 65;
      state.isGetLounageLoading = true;
      state.loungeData = [];
      state.isGetLounageError = false;
      state.loungeError = [];
    },
    getAirportLoungeSuccess: (state, action) => {
      state.progress = 100;
      state.isGetLounageLoading = false;
      state.loungeData = action.payload;
      state.isGetLounageError = false;
      state.loungeError = [];
    },
    getAirportLoungeFailure: (state, action) => {
      state.progress = 100;
      state.isGetLounageLoading = false;
      state.loungeData = [];
      state.isGetLounageError = true;
      state.loungeError = action.payload;
    },

    // Events
    getEventsList: (state) => {
      state.isGetEventsLoading = true;
      state.eventsData = [];
      state.isGetEventsError = false;
      state.eventsError = [];
    },
    getEventsSuccess: (state, action) => {
      state.isGetEventsLoading = false;
      state.eventsData = action.payload;
      state.isGetEventsError = false;
      state.eventsError = [];
    },
    getEventsFailure: (state, action) => {
      state.isGetEventsLoading = false;
      state.eventsData = [];
      state.isGetEventsError = true;
      state.eventsError = action.payload;
    },
    getEventById: (state) => {
      state.isGetEventByIdLoading = true;
      state.eventByIdData = [];
      state.isGetEventByIdError = false;
      state.eventByIdError = [];
    },
    getEventByIdSuccess: (state, action) => {
      state.isGetEventByIdLoading = false;
      state.eventByIdData = action.payload;
      state.isGetEventByIdError = false;
      state.eventByIdError = [];
    },
    getEventByIdFailure: (state, action) => {
      state.isGetEventByIdLoading = false;
      state.eventByIdData = [];
      state.isGetEventByIdError = true;
      state.eventByIdError = action.payload;
    },
    // Events Categories
    getEventsCategories: (state) => {
      state.isGetEventsCategoriesLoading = true;
      state.eventsCategoriesData = [];
      state.isGetEventsCategoriesError = false;
      state.eventsCategoriesError = [];
    },
    getEventsCategoriesSuccess: (state, action) => {
      state.isGetEventsCategoriesLoading = false;
      state.eventsCategoriesData = action.payload;
      state.isGetEventsCategoriesError = false;
      state.eventsCategoriesError = [];
    },
    getEventsCategoriesFailure: (state, action) => {
      state.isGetEventsCategoriesLoading = false;
      state.eventsCategoriesData = [];
      state.isGetEventsCategoriesError = true;
      state.eventsCategoriesError = action.payload;
    },
    // Event Countries
    getEventsCountries: (state) => {
      state.isGetEventsCountriesLoading = true;
      state.eventsCountriesData = [];
      state.isGetEventsCountriesError = false;
      state.eventsCountriesError = [];
    },
    getEventsCountriesSuccess: (state, action) => {
      state.isGetEventsCountriesLoading = false;
      state.eventsCountriesData = action.payload;
      state.isGetEventsCountriesError = false;
      state.eventsCountriesError = [];
    },
    getEventsCountriesFailure: (state, action) => {
      state.isGetEventsCountriesLoading = false;
      state.eventsCountriesData = [];
      state.isGetEventsCountriesError = true;
      state.eventsCountriesError = action.payload;
    },
    // Event Cities
    getEventCities: (state) => {
      state.isGetEventCitiesLoading = true;
      state.eventCitiesData = [];
      state.isGetEventCitiesError = false;
      state.eventCitiesError = [];
    },
    getEventCitiesSuccess: (state, action) => {
      state.isGetEventCitiesLoading = false;
      state.eventCitiesData = action.payload;
      state.isGetEventCitiesError = false;
      state.eventCitiesError = [];
    },
    getEventCitiesFailure: (state, action) => {
      state.isGetEventCitiesLoading = false;
      state.eventCitiesData = [];
      state.isGetEventCitiesError = true;
      state.eventCitiesError = action.payload;
    },
    // Bookings

    setBookingState: (state, action) => {
      state.bookingState = action.payload;
    },

    getHotelBook: (state, action) => {
      state.isHotelBookLoading = true;
      state.hotelBookData = [];
      state.isHotelBookError = false;
      state.hotelBookError = [];
    },
    getHotelBookSuccess: (state, action) => {
      state.isHotelBookLoading = false;
      state.hotelBookData = action.payload;
      state.isHotelBookError = false;
      state.hotelBookError = [];
    },
    getHotelBookFailure: (state, action) => {
      state.isHotelBookLoading = false;
      state.hotelBookData = [];
      state.isHotelBookError = true;
      state.hotelBookError = action.payload;
    },

    // flight booking
    getFlightBook: (state, action) => {
      state.isFlightBookLoading = true;
      state.flightBookData = [];
      state.isFlightBookError = false;
      state.flightBookError = [];
    },
    getFlightBookSuccess: (state, action) => {
      state.isFlightBookLoading = false;
      state.flightBookData = action.payload;
      state.isFlightBookError = false;
      state.flightBookError = [];
    },
    getFlightBookFailure: (state, action) => {
      state.isFlightBookLoading = false;
      state.flightBookData = [];
      state.isFlightBookError = true;
      state.flightBookError = action.payload;
    },

    //confirmBookingPage
    checkBookingStatus: (state) => {
      state.bookingStatus = true;
    },

    // type and currency data
    getTypeAndCurrency: (state, action) => {
      state.type = action.payload.type;
      state.currency = action.payload.currency;
    },

    //car Rental
    getcarlist: (state, action) => {
      state.iscarBookLoading = false;
      state.carBookData = [];
      state.iscarBookError = true;
      state.carBookError = action.payload;
    },
    setProfileSubPage: (state, action) => {
      state.getProfileSubPage = action.payload;
    },

    // car rental
    getSearchCarList: (state) => {
      state.isGetCarLoading = true;
      state.carData = [];
      state.isGetCarError = false;
      state.carErrors = [];
    },
    getSearchCarSuccess: (state, action) => {
      state.isGetCarLoading = false;
      state.carData = action.payload;
      state.isGetCarError = false;
      state.carErrors = [];
    },
    getSearchCarFailer: (state, action) => {
      state.isGetCarLoading = false;
      state.carData = [];
      state.isGetCarError = true;
      state.carErrors = action.payload;
    },

    // Get Ip data
    getIpData: (state) => {
      state.ipData = {};
    },
    getIpDataSuccess: (state, action) => {
      state.ipData = action.payload;
    },
    getIpDataFailure: (state, action) => {
      state.ipData = {};
    },
    setActivitesUpdating: (state, action) => {
      state.activitesUpdating = action.payload;
    },

    // set hotel price type
    setHotelPriceType: (state, action) => {
      state.hotelPriceType = action.payload;
    },
  },
});

export const {
  // currency
  setPreferedCurrency,
  setSupportedCurrencies,
  setExchangeRateForPreferedCurrency,
  // Login , Sing-up and Reset password
  userLogin,
  userLoginSuccess,
  userLoginFailure,
  userInputErrorHandle,
  userVerifyOTP,
  userVerifyOTPSuccess,
  userVerifyOTPFailure,
  userVerifyOTPCancel,
  userResendOTP,
  userResendOTPSuccess,
  userResendOTPFailure,
  userSignup,
  userSignupSuccess,
  userSignupFailure,
  userPasswordReset,
  userPasswordResetSuccess,
  userPasswordResetFailure,
  userPasswordUpdate,
  userPasswordUpdateSuccess,
  userPasswordUpdateFailure,
  userAfterLoginResetPasswordUpdate,
  userAfterLoginResetPasswordUpdateSuccess,
  userAfterLoginResetPasswordUpdateFailure,
  userAfterLoginResetPasswordUpdateCancel,
  userResetPassWordOTP,
  userResetPassWordOTPSuccess,
  userResetPassWordOTPFailure,
  userEmailUpdateOTP,
  userEmailUpdateOTPSuccess,
  userEmailUpdateOTPFailure,
  userEmailUpdateOTPCancel,
  userEmailUpdate,
  userEmailUpdateSuccess,
  userEmailUpdateFailure,
  userEmailUpdateCancel,
  userLoginResetPasswordCancel,
  userResetPasswordScreenCancel,
  userAfterLoginResetPasswordSet,
  userAfterLoginResetPasswordSetSuccess,
  userAfterLoginResetPasswordSetFailure,
  // Home
  getTopDestination,
  getTopDestinationSuccess,
  getTopDestinationFailure,
  getPopularAttraction,
  getPopularAttractionSuccess,
  getPopularAttractionFailure,
  getPopularAttractionCountries,
  getPopularAttractionCountriesSuccess,
  getPopularAttractionCountriesFailure,
  getBackgroungImagesList,
  getBackgroungImagesListSuccess,
  getBackgroungImagesListFailure,
  getUserProfile,
  getUserProfileSuccess,
  getUserProfileFailure,
  getCitiesList,
  getCitiesListSuccess,
  getCitiesListFailure,
  generateTrip,
  generateTripSuccess,
  generateTripFailure,
  setDetailedTripInfo,
  // Flight
  getFlightsList,
  getFlightSuccess,
  getFlightFailure,
  getFlightsPopularRoutesList,
  getFlightPopularRoutesSuccess,
  getFlightPopularRoutesFailure,
  getFlightsData,
  getFlightDataSuccess,
  getFlightDataFailure,
  getFlightRevalidate,
  getFlightRevalidateSuccess,
  getFlightRevalidateFailure,
  getFlightRevalidateReset,

  //type and currency
  getTypeAndCurrency,

  //bucks for flight
  setBucksApply,
  setBucksNotApply,

  // flight change price
  setFlightChangePrice,
  setFlightChangePriceOff,

  // bucks for hotel
  setBucksApplyHotel,
  setBucksNotApplyHotel,

  //set false
  setBucksApplyFalse,

  //old flight data while change
  getFlightDetails,
  getFlightDetailsSuccess,
  getFlightDetailsFailure,
  getChangeFlightConfirmData,

  //change flight data
  getChangeFlightData,
  getChangeFlightDataSucces,
  getChangeFlightDataFailure,

  // after flight change response
  getchangeFlightProcessData,
  getchangeFlightProcessDataSuccess,
  getchangeFlightProcessDataFailure,

  //cancel flight
  cancelFlight,
  cancelFlightSuccess,
  cancelFlightFailure,

  //cancel hotel
  cancelHotel,
  cancelHotelSuccess,
  cancelHotelFailure,

  // Static content
  getTopCities,
  getTopCitiesSuccess,
  getTopCitiesFailure,
  // Hotel
  getSelectedHotelData,
  getHotelAndCities,
  getHotelAndCitiesSuccess,
  getHotelAndCitiesFailure,
  getHotelList,
  getHotelListSuccess,
  getHotelListFailure,
  getHotelByCity,
  getHotelByCitySuccess,
  getHotelByCityFailure,
  getHotelById,
  getHotelByIdSuccess,
  getHotelByIdFailure,
  getHotelMetadataById,
  getHotelMetadataByIdSuccess,
  getHotelMetadataByIdFailure,
  getHotelReviewById,
  getHotelReviewByIdSuccess,
  getHotelReviewByIdFailure,
  getHotelRoomsById,
  getHotelRoomsByIdSuccess,
  getHotelRoomsByIdFailure,
  getHotelsCordinateByCity,
  getHotelsCordinateByCitySuccess,
  getHotelsCordinateByCityFailure,
  //Car-Rental
  getcarlist,
  getLocations,
  getLocationsFailure,
  getLocationsSuccess,
  getSearchCarList,
  getSearchCarSuccess,
  getSearchCarFailer,
  // Airport
  getAirportLounge,
  getAirportLoungeSuccess,
  getAirportLoungeFailure,
  // Component
  setCompareHotel,
  removeCompareHotel,
  setMultiFlight,
  removeMultiFlight,
  setImgIndex,
  setProgress,
  getEventsList,
  getEventsSuccess,
  getEventsFailure,
  getEventById,
  getEventByIdSuccess,
  getEventByIdFailure,
  getEventsCategories,
  getEventsCategoriesSuccess,
  getEventsCategoriesFailure,
  getEventsCountries,
  getEventsCountriesSuccess,
  getEventsCountriesFailure,
  getEventCities,
  getEventCitiesSuccess,
  getEventCitiesFailure,
  // Bookings

  setBookingState,
  getHotelBook,
  getHotelBookSuccess,
  getHotelBookFailure,
  getFlightBook,
  getFlightBookSuccess,
  getFlightBookFailure,
  checkBookingStatus,
  setProfileSubPage,
  getIpData,
  getIpDataSuccess,
  getIpDataFailure,
  // trip planner activites update
  setActivitesUpdating,
  resetBucks,
} = userSlice.actions;

export default userSlice.reducer;
