import axios from 'axios';
import url from '../../api/api';
import toast from 'react-hot-toast';

const setupAxiosInterceptor = () => {
  const axiosInstance = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        localStorage.getItem('isUserLoggedIn') &&
        error.response &&
        error.response.status === 401
      ) {
        toast.error('Your session expired, please re-login');
        localStorage.clear();
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default setupAxiosInterceptor;
